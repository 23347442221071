<script setup lang="ts">
import type { Post } from '@/types'

const { count } = defineProps<{
  title: string
  count: number
  email?: boolean
  description?: string

  posts: Post[]

  type: string
  input?: boolean

  avatar?: string
}>()

const { editorialEmail } = useAppConfig()

if (count < 1)
  await navigateTo('/')
</script>

<template>
  <section>
    <UContainer class="py-8">
      <div class="flex gap-x-12 mb-8">
        <div class="flex-1">
          <div class="flex gap-4">
            <div v-if="avatar">
              <NuxtImg
                format="avif"
                class="size-14 object-cover rounded-md border shadow"
                :src="avatar"
              />
            </div>
            <div>
              <div class="flex gap-2">
                <span
                  class="text-base font-semibold text-primary tracking-wide uppercase"
                >
                  {{ type }}
                </span>
                <NuxtLink
                  v-if="email"
                  :to="`mailto:${editorialEmail}`"
                >
                  <UBadge color="gray">
                    {{ editorialEmail }}
                  </UBadge>
                </NuxtLink>
              </div>
              <span class="text-3xl font-bold text-gray-800">
                <div class="flex flex-wrap items-center gap-2">
                  <h1>
                    {{ title }}
                  </h1>
                  <UBadge>{{ count }} Artykułów</UBadge>
                </div>
              </span>
            </div>
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="description"
            class="text-lg mt-2 font-light text-gray-700"
            v-html="description"
          />
          <div
            v-if="input"
            class="mt-4"
          >
            <slot name="input" />
          </div>
        </div>
      </div>

      <slot />

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <CleanCard
          v-for="post in posts"
          :id="post.id"
          :key="post.id"
        />
      </div>
    </UContainer>

    <AppPagination :total="count" />
  </section>
</template>
